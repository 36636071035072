<template>
  <div>
    <titleBar
      :title="'Search '"
      :img="bot?.avatar"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />

    <div class="sectionNA">
      <div class="containerNA NHAw800">
        <b-field horizontal label=" ">
          <header>
            <h2 class="title is-2">
              <span>Search results</span>
            </h2>
          </header>
        </b-field>
        <hr />

        <!-- START-->

        <b-field horizontal>
          <div>
            <h3 class="title is-4">Include in search engine</h3>
          </div>
        </b-field>

        <b-field horizontal>
          <b-switch v-model="bot.enableSearch" type="is-info">
            Enable Search appearance
            <b-tooltip type="is-dark" multilined label="  Your chatbot will answer user search querries">
              <i class="fal fa-question-circle" aria-hidden="true"></i>
            </b-tooltip>
          </b-switch>
        </b-field>

        <div v-if="bot.enableSearch">
          <b-field horizontal label="handle">
            <b-input size="is-" placeholder="myAiProject" :value="bot.handle" :disabled="true"></b-input>
          </b-field>

          <b-field horizontal label="System">
            <b-input
              type="textarea"
              placeholder="Respond to query as a profesional gardener, include detailled information and follow up questions to better understand the user goals. Friendly voice. Short concise respones"
              v-model="bot.searchPrompt"
              :loading="posting"
              :disabled="posting"
            ></b-input>
          </b-field>

          <b-field horizontal label="Examples query (one per line)">
            <b-input
              type="textarea"
              placeholder=""
              v-model="bot.searchExamples"
              :loading="posting"
              :disabled="posting"
            ></b-input>
          </b-field>

          <!-- buefy switch , about monetization yes no
      <b-field horizontal>
        <b-switch v-model="bot.monetized" type="is-info">
          Monetize
          <b-tooltip
            type="is-dark"
            multilined
            label="Your chatbot will be listed in the public directory, and users will be able to find it."
          >
            <i class="fal fa-question-circle" aria-hidden="true"></i>
          </b-tooltip>
        </b-switch>
      </b-field> -->

          <!-- 
      <b-field horizontal label="Mode">
        <b-select v-model="bot.monetizationMode" placeholder="Select a mode" :disabled="posting">
          <option value="demo">Demo</option>
          <option value="pro">Pro</option>
          <option value="unlimited">Unlimited</option>
        </b-select>
      </b-field> -->

          <b-field horizontal label="subscriptions" v-if="bot.pricingMode == 'subscription'">
            <div class="box">
              <span class="tag is-warning is-light">Premium plan required</span><br /><br />
              <p>Earn from subscrbers</p>
              <p>Set your prices & your rules for your AI content.</p>
              <!-- subsctibe button is dark, router link to /:bot/plans -->
              <br />
              <router-link to="/bot/plans" class="button is-dark"> Pricing</router-link>
            </div>
          </b-field>

          <b-field horizontal label="Unlimited access" v-if="bot.pricingMode == 'unlimited'">
            <div class="box">
              <span class="tag is-warning is-light">Premium plan required</span><br /><br />
              <p>Provide unlimited access to your bots</p>
              <p>The premium plan will let thoushands of people interact with your bot for free every month.</p>
              <!-- subsctibe button is dark, router link to /:bot/plans -->
              <br />
              <router-link to="/bot/plans" class="button is-dark">Pricing</router-link>
            </div>
          </b-field>

          <!-- END     
    -->
          <b-field horizontal label="   ">
            <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting"
              >Save changes</b-button
            >
          </b-field>
        </div>

        <b-field v-else>
          <div>
            <br />
            <br />
            <br />
            <div class="message is-info">
              <strong>How it works</strong>
              <br />
              .....
            </div>
          </div>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
//import Editor from "@/components/e/EditorV";

export default {
  //name: "readmeEditor",
  components: {
    //Editor,
  },
  props: {
    bot: {
      type: Object,
      default: null,
    },
    posting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // readmeJson: defaultReadme, // "432432432",
    };
  },
  methods: {
    save() {
      this.$emit("save");
    },
  },
};
</script>

<style></style>
